<template>
    <el-dialog
        title="投递职位"
        :visible.sync="dialogVisible"
        :before-close = 'handleClose'
        >
        <el-upload
            class="upload-demo"
            :data="names"
            action="https://www.ccqjob.com/rcyj/post/uploadResume"
            :on-success="handleAvatarSuccess"
            :disabled="true"
            multiple>
        </el-upload>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>

</template>

<script>
    export default {
        name: "dialog",
        props :[
            'dialogVisible',
            'activityName',
            'companyName',
            'postName',
        ],
        data() {
            return {
                SonDialogVisible:false,
            };
        },
        computed: {
            names() {
                return {
                    activityName: this.activityName,
                    companyName: this.companyName,
                    postName: this.postName
                }
            }
        },
        methods: {
            handleClose() {
                this.$emit('sendDialogVisible',this.SonDialogVisible)
            },
            handleAvatarSuccess() {
                this.$message({
                    type: 'success',
                    message: '上传简历成功!'
                })
            },
        }
    }
</script>

<style >
    body{
        padding-right: 0px !important;
        overflow: hidden;
    }
</style>
